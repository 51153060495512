/* ProjectGrid.css */
.body {
  font-family: 'Acumin Variable Concept', sans-serif;
  background-color: #f2fff2;
  color: #101b11;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #f2fff2;
    margin-left: 10px;
    margin-right: 25px;
    margin-top: 100px;
    padding-left: 10px;
  }

  .project-grid a {
    text-decoration: none;
    padding: 0%;
    margin: 0%;
  }

  @media only screen and (max-width: 768px) {
  .project-grid {
    margin-top: 110px;
    grid-template-columns: repeat(1, 1fr); /* Display projects in a single column on smaller screens */
    margin-left: 0;
    margin-right: 0;
    padding-right: 20px;
    padding-top: 35px;
    padding-left: 20px;
  }
}