/* About.css */
.about {
  margin-top: 100px;
  padding: 0px 20px 20px 20px;
  font-family: 'Acumin Variable Concept', sans-serif;
  background-color: #f2fff2;
  color: #101b11;
  display: flex;
  flex-wrap: wrap;
}

.text-desc {
  flex: 1 1 calc(50% - 10px);
  margin-right: 20px;
}

.text-desc p {
  margin: 0;
}

.text-desc a {
  margin: 0px 0px 0px -4px;
  padding: 4px;
  text-decoration: none;
  color: #101b11;
  transition: background-color 0.3s, color 0.3s;
}

.text-desc a:hover, .text-desc a:active {
  background-color: #101b11;
  color: #f2fff2;
}

.exhibitions {
  margin-top: 30px;
}

.exhibitions i {
  font-style: italic;
}

.exhibitions ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.exhibitions li {
  margin-bottom: 16px;
}


.gallery {
  flex: 1 1 calc(50% - 10px);
}

.gallery-item img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .about {
    margin-top: 130px;
    padding: 10px;
    margin-left: 10px;
    flex-direction: column;
  }

  .text-desc {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .gallery-item {
    flex: 1 1 calc(100% - 10px);
  }
}
