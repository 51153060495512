/* ProjectCard.css */

.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0px;
  background-color: #f2fff2;
  overflow: hidden;
  /* transition: opacity 0.5s ease-in-out; */
}

.project-image,
.project-video {
  width: 100%;
  height: auto;
  padding: 5px;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.project-card h3 {
  margin-top: 40px;
  opacity: 0;
  font-family: "Acumin Variable Concept", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #101b11;
  /* transition: opacity 0.5s ease-in; */
  transition: opacity 0.1s ease-in 0.1s;
}

/* 
.project-card:hover .project-image,
.project-card:active .project-image {
  opacity: 0;
}

.project-card:hover .project-video,
.project-card:active .project-video {
  opacity: 1;
}
*/

.project-card:hover h3 {
  opacity: 1;
} 
