/* NavigationBar.css */
.body {
  font-family: 'Acumin Variable Concept', sans-serif;
  background-color: #f2fff2;
  color: #101b11;
}

.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background-color: #f2fff2;
  padding: 50px 30px 0px 10px;
  /* margin-top: 50px; */
  /* margin-right: 30px; */
  margin-left: 10px;
  margin-bottom: 40px;
  z-index: 999;
}

.nav-cell {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}



.nav-cell a:hover, a:active {
  color: #f2fff2;
  background-color: #101b11;
}

.nav-cell a {
  text-decoration: none;
  font-family: 'Acumin Variable Concept', sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 3px 5px;
  margin: 0;
  color: #101b11;
  transition: color 0.2s;
  text-align: left;
}
.nav-cell-left {
  justify-content: flex-start;
}

.nav-cell-right {
  justify-content: flex-end;
}

.nav-cell-right a {
  text-align: right;
}

/* 
.nav-cell-left {
 flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */

/* .nav-cell-right {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
} */

/* .nav-cell-right a:hover {
  color: #f2fff2;
  background-color: #101b11;
}

.nav-cell-left a:hover {
  color: #f2fff2;
  background-color: #101b11;
}

.nav-cell-right a {
  text-decoration: none;
  font-family: 'Acumin Variable Concept', sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 3px 5px;
  color: #101b11;
  transition: color 0.2s;
}

.nav-cell-left a {
  text-decoration: none;
  font-family: 'Acumin Variable Concept', sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 3px 5px;
  margin: 0;
  color: #101b11;
  transition: color 0.2s;
} */
/* 
.nav-text-left {
  font-family: 'Acumin Variable Concept', sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 3px 5px;
  margin: 0;
  transition: color 0.2s;
}

.nav-text-right {
  font-family: 'Acumin Variable Concept', sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 3px 5px;
  margin: 0;
  transition: color 0.2s;
}
 */


 /* Add styles for mobile screens */
 @media only screen and (max-width: 768px) {
  .navigation-bar {
    margin-top: 0px;
    padding-left: 0px;
    width: 100%;
    margin-right: 10px;
    flex-direction: column;
    padding-right: 25px;
    padding-top: 20px;
  }

  .nav-cell {
    width: 100%; /* Make navigation cells full width */
    margin: 5px 0; /* Add spacing between navigation items */
  }

  .nav-cell-left {
    flex: 2;
  }
}