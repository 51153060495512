/* Contact.css */

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 100px 20px 20px 20px;
  box-sizing: border-box;
  max-width: 100%;
  background-color: #f2fff2;
  font-weight: 600;
}

.contact-image {
  flex: 1; /* Take up the available space */
}

.contact-image img {
  width: 100%;
  max-width: 100%;
}

.contact-text {
  flex: 1; /* Take up the available space */
  display: flex;
  flex-direction: column;
  padding-left: 20px; /* Add some spacing between image and text */
  align-items: flex-end;
}
.email-address {
  margin-bottom: 20px;
}

.email-address,
.instagram-link {
  padding: 3px;
  text-decoration: none;
  color: #101b11;
  transition: background-color 0.2s, color 0.2s;
}

.email-address a,
.instagram-link a {
  padding: 3px;
  text-decoration: none;
  color: #101b11;
  transition: background-color 0.2s, color 0.2s;
}

.email-address a:hover,
.instagram-link a:hover,
a:active {
  color: #f2fff2;
  background-color: #101b11;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column-reverse; 
    margin-top: 140px;
    margin-right: 14px;
    align-items: flex-end;
  }

  .instagram-link {
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .email-address {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .contact-image {
    order: -1; /* Change the order on mobile */
  }
}
