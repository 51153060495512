/* styles.css */
body {
  margin: 0;
  font-family: 'Acumin Variable Concept', sans-serif;
  background-color: #f2fff2;
}
  
.app {
  background-color: #f2fff2;
}
  