/* ProjectPage.css */
.body {
  font-family: 'Acumin Variable Concept', sans-serif;
  background-color: #f2fff2;
  color: #101b11;
}

.project-page {
  display: flex;
  align-items: flex-start;
  /* padding: 10px 20px; */
  margin: 100px 20px 30px 10px;
  position: relative;
  padding-left: 10px;
  overflow: auto;
}

/* Left Container - 2/3 width */
.left-container {
  flex: 5;
  width: 66.66%;
  overflow: hidden;
  display: flex; 
  flex-direction: column; 
  align-items: flex-start; 
  position: relative;
}

.first-row {
  display: flex;
  align-items: flex-start;
}

.left-column {
  flex: 2;
  width: 50%;
  /* padding-right: 10px; */
}

.project-image {
  width: 100%;
  max-width: 100%;
  padding: 5px;
}

.project-image img {
  width: 100%;
  max-width: 100%;
}

h3 {
  font-family: 'Acumin Variable Concept', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #101b11;
  margin-top: 40px;
  margin-left: 10px;
  margin-bottom: 40px;
}

.middle-column {
  flex: 2;
  width: 50%;
  padding-right: 5px;
}

.project-description {
  text-align: justify;
  margin-top: 0;
  align-items: end;
}
.justified-text {
  width: 75%;
  margin: 0%;
  padding-top: 5px;
  margin-left: auto; 
  margin-right: 0;
}

.right-column {
  flex: 1;
  width: 50%;
}


.second-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-left: 5px;
  margin-top: 10px;
}

.third-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-left: 5px;
}

.project-imgs {
  flex: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto; 
  position: relative;
  margin-right: 20px;
  padding-right: 10px;
}

.project-imgs img {
  width: 100%;
  margin-top: 5px;
  margin-right: 10px;
}

.video-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 30px;
}

.video-section iframe {
  width: 100%;
  height: 45vw;
  margin-right: 10px;
  border: 0;
}

/* Right Container - 1/3 width */
.right-container {
  flex: 1;
  width: 33.33%;
  margin-left: auto;
}

.project-info {
  padding-top: 5px;
  background-color: #f2fff2;
  z-index: 1;
  padding-right: 5px;
}

.fixed-info {
  position: fixed;
  width: 100%; 
  /* float: right; */ 
  max-width: 15%;
}

/* .fixed-info p {
  position: relative;
  white-space: break-word;
  word-wrap: pre-wrap;
} */


.info-text {
  margin-top: 0;
}

.images-container {
  flex: 2;
  max-width: 100%;
  overflow: auto; 
}

.colab-text {
  margin: 0;
}

.colab-text a {
  margin: 0px 0px 0px -4px;
  padding: 4px;
  text-decoration: none;
  color: #101b11;
  transition: background-color 0.3s, color 0.3s;
}

.colab-text a:hover, a:active {
  background-color: #101b11;
  color: #f2fff2;
}


@media only screen and (max-width: 768px) {
  .project-page {
    flex-direction: column;
    margin-right: 5px;
    margin-top: 130px;
    max-width: 100%;
    overflow: hidden;
  }

  /* .project-info, */
  .left-container,
  .right-container {
    width: 100%;
    padding: 0px;
    margin-right: 5px;

  }

  .right-container {
    order: 1;
    top: 0px;
  }

  .project-info {
    order: -1;
    margin-bottom: 20px; 
  }

  .fixed-info {
    position: sticky;  
    max-width: 100%;
  }

  .left-container{
    margin-top: 0px; 
    order: 2;
  }

  .left-column,
  .middle-column,
  .right-column {
    width: 100%;
  }

  .first-row {
    flex-direction: column;
    margin-right: 15px;
  }

  .project-image {
    padding: 0px;
  }
  .project-image video {
    padding: 0px;
  }

  .project-description {
    text-align: justify;
    margin-right: 0px
  }

  .justified-text {
    width: 100%;
  }

  .second-row {
    margin-top: 20px; 
    margin-left: 0px;
  }

  .video-section{
    margin-right: 15px;
  }

  .third-row{
    margin-left: 0;
  }

  .project-imgs{
    margin-right: 5px;
    overflow: hidden;
    position: static;
  }

  .fixed-info-mobile {
    position: sticky;
    top: 0;
    background-color: #f2fff2;
    z-index: 1;
    padding-right: 5px;
    margin-right: 10px;
  }


  h3 {
    margin-left: 0px;
  }
}